.faire-cadeau-modal{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0%;
    left: 0%;
}

.faire-cadeau-modal-bg{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.faire-cadeau-modal-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 500px;
    height: auto;
    background-color: #fff;
    border-radius: 15px;
}

.close-faire-cadeau-modal{
    width: 40px;
    height: 40px;
    margin: 5px 0 -10px 5px;
    background-image: url('../../assets/croix.svg');
    background-size: cover;
    cursor: pointer;
}

.faire-cadeau-modal-container h3{
    text-align: center;
    font-size: 28px;
}
.faire-cadeau-modal-container h4{
    text-align: center;
    font-size: 22px;
    font-weight: bold;
}
.faire-cadeau-modal-container p{
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.faire-cadeau-modal-container select{
    width: calc(100% - 20px);
    margin: 5px 10px;
    padding: 6px 12px;
    border: none;
    font-size: 20px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    outline: none;
    cursor: pointer;
}
.faire-cadeau-modal-container select option{
    padding: 30px;
    cursor: pointer; 
}


.faire-cadeau-modal-container input, .faire-cadeau-modal-container textarea {
    width: calc(100% - 20px);
    margin: 5px 10px;
    line-height: 28px;
    border: 2px solid transparent;
    border-bottom-color: #777;
    padding: .2rem 0;
    outline: none;
    background-color: transparent;
    color: #0d0c22;
    transition: .3s cubic-bezier(0.645, 0.045, 0.355, 1);
    text-align: center;
    font-size: 18px;
    resize: none;
}

.faire-cadeau-modal-container input:focus, .faire-cadeau-modal-container input:hover,
.faire-cadeau-modal-container textarea:focus, .faire-cadeau-modal-container textarea:hover {
    outline: none;
    padding: .2rem 1rem;
    border-radius: 1rem;
    border-color: #7a9cc6;
}

.faire-cadeau-modal-container input::placeholder, .faire-cadeau-modal-container textarea::placeholder {
    color: #777;    
}

.faire-cadeau-modal-container input:focus::placeholder,
.faire-cadeau-modal-container textarea:focus::placeholder {
    opacity: 0;
    transition: opacity .3s;
}

.faire-cadeau-modal-container .send-cadeau-btn{
    width: fit-content;
    margin: 5px auto 10px;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    background-color: #333;
    padding: 6px 12px;
    border-radius: 7px;
    cursor: pointer;
}