.creation-commu-modal{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0%;
    left: 0%;
}

.creation-commu-modal-bg{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.creation-commu-modal-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 500px;
    height: auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.close-creation-commu-modal{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 5px;
    left: 5px;
    background-image: url('../../assets/croix.svg');
    background-size: cover;
    cursor: pointer;
}

.creation-commu-modal-container h3{
    font-size: 26px;
    text-align: center;
    margin-top: 30px;
}


.creation-commu-input {
    width: calc(100% - 40px);
    margin: 10px 0;
    line-height: 28px;
    border: 2px solid transparent;
    border-bottom-color: #777;
    padding: .2rem 0;
    outline: none;
    background-color: transparent;
    color: #0d0c22;
    font-size: 18px;
    resize: none;
    transition: .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
textarea.creation-commu-input {
    font-size: 15px;
}

.creation-commu-input:focus, .creation-commu-input:hover {
    outline: none;
    padding: .2rem 1rem;
    border-radius: 1rem;
    border-color: #7a9cc6;
}

.creation-commu-input::placeholder {
    color: #777;    
}

.creation-commu-input:focus::placeholder {
    opacity: 0;
    transition: opacity .3s;
}

.creation-commu-edit-validate{
    background-color: #00A513;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    border: none;
    margin: 0 2px;
}