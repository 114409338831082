.bon-achat-modal{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0%;
    left: 0%;
}

.bon-achat-modal-bg{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.bon-achat-modal-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 600px;
    height: 600px;
    background-color: #fff;
    border-radius: 15px;
}

.close-bon-achat-modal{
    width: 40px;
    height: 40px;
    margin: 5px 0 -10px 5px;
    background-image: url('../../assets/croix.svg');
    background-size: cover;
    cursor: pointer;
}

.bon-achat-modal-container h3{
    text-align: center;
    font-size: 28px;
}

.bon-achat-modal-container .list-bon-achat-container{
    height: 526px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow-y: scroll;
}

@media screen and (max-width: 380px) {
    .bon-achat-modal-container h3{
        font-size: 23px;
    }
}