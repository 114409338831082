.edit-commu-modal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

.edit-commu-modal-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
}

.edit-commu-modal-close{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    left: 10px;
    background-image: url('../../assets/croix.svg');
    background-size: cover;
    cursor: pointer;
    
}

.edit-commu-modal-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    min-width: 300px;
    width: fit-content;
    max-width: 600px;
    height: auto;
    padding: 15px 10px;
    border-radius: 15px;
    text-align: center;
}

/* ============= INPUT FILE ====================*/
.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    /* max-width: 80%; */
    font-size: 1.25rem;
    /* 20px */
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.no-js .inputfile + label {
    display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
}

.inputfile-4 + label {
    color: #FFB300;
    transition: all .2s ease-in-out;
}

.inputfile-4:focus + label,
.inputfile-4.has-focus + label,
.inputfile-4 + label:hover {
    color: #DE0009;
}

.inputfile-4 + label figure {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #FFB300;
    display: block;
    padding: 20px;
    margin: 0 auto 10px;
    transition: all .2s ease-in-out;
}

.inputfile-4:focus + label figure,
.inputfile-4.has-focus + label figure,
.inputfile-4 + label:hover figure {
    background-color: #DE0009;
}

.inputfile-4 + label svg {
    width: 100%;
    height: 100%;
    fill: #fff;
}

/* =========== INPUT =============*/
.input-edit-commu {
    width: 100%;
    margin: 10px 0;
    line-height: 28px;
    border: 2px solid transparent;
    border-bottom-color: #777;
    padding: .2rem 0;
    outline: none;
    background-color: transparent;
    color: #0d0c22;
    transition: .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.input-edit-commu:focus, .input-edit-commu:hover {
    outline: none;
    padding: .2rem 1rem;
    border-radius: 1rem;
    border-color: #7a9cc6;
}

.input-edit-commu::placeholder {
    color: #777;    
}

.input-edit-commu:focus::placeholder {
    opacity: 0;
    transition: opacity .3s;
}

.edit-commu-btn {
    background-color: #444141;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    border: none;
    margin: 10px;
}