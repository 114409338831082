.return-all-commu{
    position: absolute;
    top: 10px;
    left: 10px;
    width: fit-content;
    padding: 4px 10px;
    border-radius: 6px;
    background-color: #DE0009;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    transition: all .3s ease-in-out;
}
.return-all-commu:hover{
    background-color: #940005;
}

.single-commu-info{
    width: fit-content;
    margin: 50px auto 0;
    display: flex;
    align-items: center;
}

.single-commu-info .single-commu-img{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-right: 10px;
}

.single-commu-info .single-commu-text{
    font-weight: bold;
    margin-left: 10px;
    font-size: 16px;
}

.single-communaute-description{
    font-size: 14px;
    color: #555;
}

.single-commu-info .single-commu-text .single-commu-btn{
    width: fit-content;
    padding: 4px 15px;
    border-radius: 9999px;
    background-color: #0040E2;
    border: solid 3px #0040E2;
    color: #fff;
    margin: 10px 0 0 0;
    font-size: 18px;
    user-select: none;
    cursor: pointer;
    text-align: center;
    transition: all .3s ease-in-out;
}
.single-commu-info .single-commu-text .single-commu-btn:hover{
    background-color: #fff;
    color: #0040E2;
}


.single-commu-info .single-commu-text .single-commu-btn.demande-envoye{
    background-color: #FFB300;
    border: solid 3px #FFB300;
}
.single-commu-info .single-commu-text .single-commu-btn.demande-envoye:hover{
    background-color: #fff;
    color: #FFB300;
}

.single-commu-info .single-commu-text .single-commu-btn.quitter-commu{
    background-color: #DE0009;
    border: solid 3px #DE0009;
}
.single-commu-info .single-commu-text .single-commu-btn.quitter-commu:hover{
    background-color: #fff;
    color: #DE0009;
}


.membres-container{
    width: 100%;
    /* height: 44%; */
    height: calc(100% - 200px);
    overflow-y: scroll;
    position: relative;
}

.membre-blocked-view{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: bold;
    color: #333;
    font-size: 20px;
}


@media screen and (min-width: 600px) {
    .single-commu-info{
        margin: 50px 0 0 100px;
    }

    .membres-container{
        height: calc(100% - 220px);
    }

    .single-commu-info .single-commu-text{
        font-size: 20px;
    }

    .single-communaute-description{
        font-size: 18px;
    }
}