.cagnotte-item{
    width: fit-content;
    margin: 5px 15px;
    cursor: pointer;
}

.cagnotte-billet{
    width: 250px;
    position: relative;
}

.cagnotte-billet img{
    width: 100%;
    height: auto;
}

.cagnotte-billet .montant-cagnotte{
    width: 100%;
    text-align: center;
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 35px;
    font-weight: bold;
}
.cagnotte-item-name{
    width: 100%;
    text-align: center;
    position: absolute;
    top: 24%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
}