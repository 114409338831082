.profil{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.profil-infos-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 20px 0 20px;
    margin: auto;
}

.profil-infos-container .profil-photo{
    width: 100px;
    height: 100px;
    background-color: grey;
    border-radius: 50%;
}
.profil-infos-container .profil-photo img{
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.profil-infos-row{
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;
}

.profil-infos-item{
    margin: 0 5px;
    text-align: center;
}

.profil-commu{
    text-decoration: none;
    color: #000;
    padding: 10px;
    width: fit-content;
    margin: auto;
    /* margin: 0 auto 10px auto; */
    display: flex;
    align-items: center;
    transition: all .3s ease-in-out;
    border-radius: 9999px;
    cursor: pointer;
}
.profil-commu:hover{
    background-color: #ddd;
}
.profil-commu-img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.profil-commu-name{
    font-size: 16px;
    font-weight: bold;
    margin-left: 20px;
}


.profil-btn-container{
    width: fit-content;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
}

.profil-edit-btn, .profil-delete-btn{
    border-radius: 9999px;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 8px 14px;
    margin: 3px 5px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.profil-edit-btn{
    background-color: #FFB300;
    border: solid 3px #FFB300;
}
.profil-edit-btn:hover{
    background-color: #fff;
    color: #FFB300;
}

.profil-delete-btn{
    background-color: #DE0009;
    border: solid 3px #DE0009;
}
.profil-delete-btn:hover{
    background-color: #fff;
    color: #DE0009;
}

@media screen and (min-width: 420px) {
    .profil-infos-container .profil-photo{
        width: 120px;
        height: 120px;
    }
}

@media screen and (min-width: 830px) {
    .profil-infos-container{
        flex-direction: row;
        flex-wrap: wrap;
    }

    .profil-infos-container .profil-photo{
        width: 150px;
        height: 150px;
    }

    /* .profil-commu{
        margin-top: 0;
    } */
    .profil-commu-img{
        width: 120px;
        height: 120px;
    }
    .profil-commu-name{
        font-size: 22px;
    }

    .profil-photo{
        margin: 0;
    }
    .profil-infos-row{
        flex-direction: row;
        margin: 10px 15px;
        font-size: 20px;
    }
    .profil-infos-item{
        margin: 0 20px;
    }
}