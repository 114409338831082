.login{
    text-align: center;
    min-height: calc(100vh - 112px);
}

.login h2{
    margin: 0px 0 10px 0;
}

.login-container{
    border: solid 1px #000;
    max-width: 600px;
    margin: 0 auto;
}

.login-container .login-header{
    display: flex;
}

.login .redirect-account{
    margin-top: 15px;
}
.login .redirect-account a{
    font-weight: bold;
    color: #14FF00;
}

.login-body{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-body input{
    width: calc(100% - 40px);
}

.password-forgot{
    font-weight: bold;
    text-align: center;
    color: #0040E2;
    cursor: pointer;
    margin-bottom: 5px;
}
.password-forgot:hover{
    text-decoration: underline;
}

/* ======= Input =========*/
.login-input {
    margin: 10px 0;
    line-height: 28px;
    border: 2px solid transparent;
    border-bottom-color: #777;
    padding: .2rem 0;
    outline: none;
    background-color: transparent;
    color: #0d0c22;
    transition: .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.login-input:focus, .login-input:hover {
    outline: none;
    padding: .2rem 1rem;
    border-radius: 1rem;
    border-color: #7a9cc6;
}

.login-input::placeholder {
    color: #777;    
}

.login-input:focus::placeholder {
    opacity: 0;
    transition: opacity .3s;
}

.login-body button {
    background-color: #444141;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    border: none;
    margin: 10px;
}