*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family:-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
  /* animation: back .3s infinite; */
  overflow: hidden;
}

@keyframes back {
  0% {
    background-color: #00A513;
  }

  25% {
    background-color: #0040E2;
  }

  50% {
    background-color: #FFB300;
  }

  75% {
    background-color: #DE0009;
  }

  100% {
    background-color: #00A513;
  }
}

*::-webkit-scrollbar {
  width: 12px;
  /* Largeur de la scrollbar */
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* Couleur de fond du curseur */
  border-radius: 6px;
  /* Rayon de bordure du curseur */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
  /* Couleur de fond du curseur au survol */
}