.cagnotte-total{
    position: relative;
    max-width: 320px;
    width: 100%;
    min-width: 260px;
    padding: 0 5px;
    margin: 10px auto 0px;
}

.cagnotte-total img{
    width: 100%;
    height: auto;
}

.cagnotte-total .cagnotte-total-libelle{
    width: 100%;
    text-align: center;
    position: absolute;
    top: 24%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.8vh;
    font-weight: bold;
}

.cagnotte-total .montant-total{
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 6vh;
    font-weight: bold;
}

.cagnotte-total-text{
    text-align: center;
    font-size: 4vh;
}

.cadeau-btn, .bon-achat-btn{
    position: relative;
    width: fit-content;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: #FFB300;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto 0px auto;
    cursor: pointer;
}
.cadeau-btn .cadeau-nb, .bon-achat-btn .bon-achat-nb{
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(-45%, 45%);
    background-color: #DE0009;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    letter-spacing: -1px;
}

.autre-cagnotte {
    font-size: 20px;
    margin: 5px 0 5px 10px;
}

.cagnotte-container{
    width: 100%;
    display: flex;
    overflow-x: scroll;
}


@media screen and (min-width: 600px) {
    .cagnotte-total{
        max-width: 360px;
    }

    .cagnotte-container{
        height: auto; 
        max-height: calc(55% - 100px);
        justify-content: center;
        flex-wrap: wrap;
        overflow-x: auto;
        overflow-y: scroll;
    }
}

@media screen and (min-width: 1200px) {
    .cadeau-btn{
        position: absolute;
        top: 10%;
        right: 10%;
    }

    .bon-achat-btn{
        position: absolute;
        top: 10%;
        left: 10%;
    }

    .cagnotte-container{
        max-height: 55%
    }
}