.mainpagemodal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

.mainpagemodal-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
}

.mainpagemodal-close{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    left: 10px;
    background-image: url('../../assets/croix.svg');
    background-size: cover;
    cursor: pointer;
    
}

.mainpagemodal-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    min-width: 300px;
    max-width: 600px;
    max-height: 600px;
    overflow-y: scroll;
    padding: 15px;
    border-radius: 15px;
    text-align: center;
}