.create-user-modal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}

.create-user-modal-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
}

.create-user-modal-close{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    left: 10px;
    background-image: url('../../assets/croix.svg');
    background-size: cover;
    cursor: pointer;
    
}

.create-user-modal-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    min-width: 300px;
    width: fit-content;
    max-width: 600px;
    height: auto;
    padding: 15px 10px;
    border-radius: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* =========== INPUT =============*/
.input-create-user {
    width: 100%;
    margin: 10px 0;
    line-height: 28px;
    border: 2px solid transparent;
    border-bottom-color: #777;
    padding: .2rem 0;
    outline: none;
    background-color: transparent;
    color: #0d0c22;
    transition: .3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.input-create-user:focus, .input-create-user:hover {
    outline: none;
    padding: .2rem 1rem;
    border-radius: 1rem;
    border-color: #7a9cc6;
}

.input-create-user::placeholder {
    color: #777;    
}

.input-create-user:focus::placeholder {
    opacity: 0;
    transition: opacity .3s;
}

.create-user-btn {
    background-color: #444141;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    border: none;
    margin: 10px;
}